<template>
  <div class="wallet-container" v-for="wallet in $store.state.notifications">
    <span class="wallet-info-title">Wallet Address: <span class="wallet-info-monospace">{{wallet.wallet_address}}</span></span>
    <span class="wallet-info-monospace">{{wallet.transaction_info}}</span>
    <span class="wallet-info-title">Callback URL: <span class="wallet-info-monospace">{{wallet.webhook_url}}</span></span>
    <span class="wallet-info-title">Success: <span class="wallet-info-monospace">{{wallet.webhook_success}}</span></span>
  </div>
</template>

<script>

export default {
  name: "Notifications",
  data(){
    return{
      wallets: null
    }
  },
}
</script>

<style scoped lang="sass">
@import '@/styles/Container/List'
</style>