import axios from 'axios';
import router from "@/router";

export function authorizeUser(state, payload) {

    const formData = new FormData();

    console.log(payload.username, payload.password)

    formData.set('username', payload.username)
    formData.set('password', payload.password)


    localStorage.removeItem('jwt_token');
    state.userInformation.jwtToken = null;

    axios.post(
        `${state.serverIpAddr}/auth/login`, formData).then((response) => {
        const token = response.data.access_token;
        console.log(token)
        console.log(response.data.error)
        state.userInformation.jwtToken = token;
        localStorage.setItem('jwt_token', token);
        router.push({path: '/'})
    });
}

export function logOut(state) {
    localStorage.removeItem('jwt_token');
    state.userInformation.jwtToken = null;
    window.location.reload();
}

export function selectTab(state, payload) {
    state.selectedTab = payload;
}

export function loadWallets(state, payload) {
    axios.get(`${state.serverIpAddr}/api/subscriptions/${payload}`,
        {headers: {Authorization: `Bearer ${state.userInformation.jwtToken}`}}).then((response) => {
        state.wallets = response.data.reverse();
    });
}

export function loadNotifications(state) {
    axios.get(`${state.serverIpAddr}/api/notifications`,
        {headers: {Authorization: `Bearer ${state.userInformation.jwtToken}`}}).then((response) => {
        state.notifications = response.data.reverse();
    });
}


export function loadMetrics(state) {
    axios.get(`${state.serverIpAddr}/metrics`).then((response) => {
        state.metrics = response.data;
    });
}

export function resendWebhooks(state) {
    axios.post(`${state.serverIpAddr}/api/webhooks/resend`, "",{headers: {Authorization: `Bearer ${state.userInformation.jwtToken}`}}).then((response) => console.log("webhook resend success: ", response.data))
}