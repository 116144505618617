import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import Auth from '@/views/Auth';
import Admin from '@/views/Admin';

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
  },
  {
    path: '/',
    name: 'Admin',
    component: Admin,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from) => {
  if (!store.getters.isAuthenticated && to.name !== 'Auth'){
    return {name: 'Auth'};
  }
});

router.beforeEach(async (to, from) => {
  if (store.getters.isAuthenticated && to.name === 'Auth') {
    return {name: 'Admin'};
  }
});

export default router;
