export const isAuthenticated = (state) => {
  const token = localStorage.getItem('jwt_token');
  if (token) {
    state.userInformation.jwtToken = token;
  }
  return localStorage.getItem('jwt_token') ||
      state.userInformation.jwtToken;
};

export const isNotificationsSelected = (state) => {
  return this.$store.state.selectedTab === 'notifications'
};